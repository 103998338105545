<template>
  <container
    v-if="hasBeenActivated"
    class="quick-signout-page"
    v-shortkey="shortkeys"
    @shortkey="handleShortkey"
  >
    <div class="p-1">
      <div class="d-flex align-items-center justify-content-between">
        <page-title class="p-2"> Quick Signout </page-title>
        <div>
          <button
            v-for="button of quickLinksButtons"
            v-bind:key="button.displayName"
            :class="`btn btn-${
              button.count && !isLoading
                ? button.displayName === 'Holds'
                  ? 'danger'
                  : 'success'
                : 'light'
            } mx-1 quicklink-button`"
            @click="handleQuicklinksButtonClick(button)"
            :disabled="isLoading"
          >
            <span v-html="button.displayName"></span>
            <span v-if="button.count && !isLoading"> ({{ button.count }})</span>
          </button>
          <button
            :class="`btn btn-danger mx-1 quicklink-button${
              selectedCaseDetails.hx ? '' : ' transparent'
            }`"
            @click="toggleHistory"
            :disabled="!selectedCaseDetails.hx"
          >
            <u>H</u>istory
          </button>
        </div>
        <div>
          <div class="mr-2 d-inline-flex flex-column" v-if="currentUser.isPathologist">
            <label><b>Show Proxy</b></label>
            <DxSwitch v-model="showProxy" :key="showProxy" />
          </div>
          <div class="mr-2 d-inline-flex flex-column">
            <label><b>Show Signed</b></label>
            <DxSwitch v-model="showSigned" :key="showSigned" />
          </div>
        </div>
      </div>
      <DxDataGrid
        :columnChooser="columnChooser"
        :data-source="accessionDataStore"
        @cell-click="handleCaseClick"
        noDataText="No cases found."
        :show-borders="true"
        :show-row-lines="true"
        :column-auto-width="true"
        :scrolling="scrolling"
        :allow-column-reordering="true"
        :remoteOperations="remoteOperations"
        :allow-column-resizing="true"
        columnResizingMode="widget"
        :hoverStateEnabled="true"
        @content-ready="onContentReady"
        @initialized="onInitialized"
        :onCellPrepared="redactLogRocket"
        :pager="pager"
        :paging="paging"
        :sorting="sorting"
        :searchPanel="searchPanel"
        :filterRow="filterRow"
        :headerFilter="headerFilter"
        :stateStoring="stateStoring"
        v-stream:selection-changed="selectionChange$"
        v-stream:content-ready="contentReady$"
        :toolbar="toolbar"
        @row-prepared="onRowPrepared"
        :keyboard-navigation="keyboardNavigation"
        :cacheEnabled="false"
      >
        <DxColumn v-for="(column, i) of columns" :key="i" v-bind="column" />
        <template v-slot:actionsTemplate="{ data: { data, key, rowIndex } }">
          <div
            class="justify-content-start d-flex align-items-center"
            :data-testid="'actions-col-' + rowIndex"
          >
            <icon-button
              v-if="permissions.CaseView"
              v-tooltip.right="`Demographics<br>(${osAltKey} + D)`"
              class="btn text-primary pointer p-0 px-1"
              @click="handleNavigateToCase(key, 'demographics')"
              icon="users"
            />
            <icon-button
              v-if="permissions.CaseSpecimenView"
              v-tooltip.right="`Specimens<br>(${osAltKey} + S)`"
              class="btn text-primary pointer p-0 px-1"
              @click="handleNavigateToCase(key, 'specimen')"
              icon="vial"
            />
            <icon-button
              v-if="permissions.CaseResultsView"
              v-tooltip.right="`Results<br>(${osAltKey} + R)`"
              class="btn text-primary pointer p-0 px-1"
              @click="handleNavigateToCase(key, 'results')"
              icon="clipboard-check"
            /><icon-button
              v-if="permissions.CaseTransactionView"
              v-tooltip.right="`Transactions<br>(${osAltKey} + T)`"
              class="btn text-primary pointer p-0 px-1"
              @click="handleNavigateToCase(key, 'transactions')"
              icon="exchange-alt"
            />
          </div>
        </template>
        <template v-slot:booleanValue="{ data }">
          <Icon v-if="data.value" class="text-success" icon="check" />
        </template>
        <template v-slot:nameAndCaseNumber>
          <div class="d-flex align-items-center justify-content-start">
            <h2 class="caseNumber">{{ selectedCaseDetails.accessionNumber }}</h2>
            <h3 class="patientName" data-private="redact">
              {{ selectedCaseDetails.patientName }}
            </h3>
          </div>
        </template>
        <template v-slot:loader>
          <loader size="small"> </loader>
        </template>
        <template v-slot:signBtn>
          <button class="btn btn-primary" @click="handleSignSelectedCase" :disabled="isLoading">
            {{ [7, 10, 13].includes(selectedCaseDetails.caseStatus) ? "Unsign" : "Sign" }}
          </button>
        </template>
      </DxDataGrid>
    </div>
    <Modal :status="isOrdersOpen" @close="isOrdersOpen = false">
      <ProcedurePopup :caseId="selectedCaseId" :inQuickSignout="true" />
    </Modal>
    <Modal :status="isHoldsOpen" @close="handleCloseHolds">
      <QuickLinksHoldPopup :caseId="selectedCaseId" @close="handleCloseHolds" />
    </Modal>
    <Modal :status="isTagsOpen" @close="isTagsOpen = false">
      <QuickLinksTagPopup :caseId="selectedCaseId" :isTag="true" />
    </Modal>
    <Modal :status="isNotesOpen" @close="isNotesOpen = false">
      <QuickLinksTagPopup :caseId="selectedCaseId" :isTag="false" />
    </Modal>
    <Modal :status="isImagesOpen" @close="isImagesOpen = false">
      <QuickLinksImagePopup :caseId="selectedCaseId" />
    </Modal>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
        :payloadToSign="payloadToSign"
      />
    </modal>
    <modal :status="isCollectedPopupOpen" @close="isCollectedPopupOpen = false">
      <collected-popup
        @submit="handleSetCollected"
        @cancel="isCollectedPopupOpen = false"
        :caseId="selectedCaseId"
        :caseDetailsProp="selectedCaseDetails"
      />
    </modal>
    <modal :status="isHistoryOpen" @close="toggleHistory">
      <CaseHistory :caseId="selectedCaseId" />
    </modal>
    <PathReportViewer v-if="isInline" class="mt-2" @close="closeReport" />
  </container>
</template>

<script>
import { DxDataGrid, DxSwitch } from "devextreme-vue";
import Container from "./common/Container.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import { DxColumn } from "devextreme-vue/data-grid";
import IconButton from "./common/IconButton.vue";
import {
  addCommasToCount,
  calcultateNameFilter,
  caseCreationMethodEnum,
  caseGridAccessionNumberFilter,
  createLocalTzDateFilter,
  formatDatetimeCell,
  scrollToElement,
  formatSSN,
  getAltKeys
  // batchRedirect
} from "@/modules/helpers";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { DropdownApi } from "@/services";
import { mapGetters, mapState } from "vuex";
import { format } from "date-fns";
import Icon from "./common/Icon.vue";
import CasesApi from "@/services/cases";
import { CaseStatusEnum } from "@/modules/enums";
import PathReportViewer from "./Reports/PathReportViewer.vue";
import { fromEvent, merge } from "rxjs";
import { filter, map, switchMap, tap, take } from "rxjs/operators";
import Modal from "@/components/common/Modal";
import getOS from "@/modules/getOs";
import ProcedurePopup from "./ProcedurePopup.vue";
import QuickLinksHoldPopup from "@/components/QuickLinksHoldPopup.vue";
import QuickLinksTagPopup from "@/components/QuickLinksTagPopup.vue";
import QuickLinksImagePopup from "@/components/QuickLinksImagePopup.vue";
import { handleErrors } from "@/modules/handleErrors";
import LabLocationPopup from "./LabLocationPopup.vue";
import CollectedPopup from "./CollectedPopup.vue";
import Loader from "./common/Loader.vue";
import CaseHistory from "@/components/CaseHistory.vue";
import eventBus, { SIGNED_CASE_FROM_PDF } from "@/modules/eventBus";

export default {
  name: "QuickSignout",
  metaInfo: {
    title: "Quick Signout",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    Container,
    PageTitle,
    DxDataGrid,
    DxColumn,
    IconButton,
    Icon,
    PathReportViewer,
    Modal,
    ProcedurePopup,
    QuickLinksHoldPopup,
    QuickLinksTagPopup,
    QuickLinksImagePopup,
    LabLocationPopup,
    CollectedPopup,
    Loader,
    CaseHistory,
    DxSwitch
  },
  data() {
    return {
      selectedCaseId: null,
      showSigned: false,
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true
      },
      pager: {
        allowedPageSizes: [5, 10, 15, 20, 25, 50],
        showInfo: true,
        infoText: "Page {0} of {1} ({2} Cases)",
        showPageSizeSelector: true,
        visible: true,
        displayMode: "full"
      },
      paging: {
        enabled: true
      },
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      sorting: {
        mode: "multiple"
      },
      searchPanel: {
        width: 300,
        visible: true
      },
      headerFilter: {
        visible: true
      },
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      billingCycles: [],
      isOrdersOpen: false,
      isHoldsOpen: false,
      isTagsOpen: false,
      isNotesOpen: false,
      isImagesOpen: false,
      selectedCaseDetails: {},
      payloadToSign: null,
      isLoading: false,
      isLocationPopupOpen: false,
      isCollectedPopupOpen: false,
      isScrolledToPathReport: false,
      pathReportPosition: null,
      isHistoryOpen: false,
      showProxy: false,
      keyboardNavigation: { enabled: false },
      selectedCaseAfterSign: false,
      isActivated: false,
      hasBeenActivated: false,
      initialHoldsCount: 0
    };
  },
  mounted() {
    if (!this.currentUser.isPathologist) {
      this.showProxy = true;
    }
  },
  activated() {
    if (!this.currentUser.isPathologist && !this.doctorProxies?.length) {
      window.notify(
        "This user is not a pathologist and has no proxies assigned, therefore you cannot sign cases out.",
        "error"
      );
      return;
    }
    if (this.hasBeenActivated) {
      this.handleRefreshGrid();
    }
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycles = res || [];
    });
    window.addEventListener("keydown", this.handleKeydown);
    eventBus.$on(SIGNED_CASE_FROM_PDF, () => this.handleRefreshGrid(true));
    if (this.reportCaseId && this.reportCaseId !== this.selectedCaseId) {
      this.$store.dispatch("report/viewPathReport", { caseId: this.selectedCaseId });
    }
    this.isActivated = true;
    this.hasBeenActivated = true;
  },
  deactivated() {
    this.isActivated = false;
    if (!this.$route?.path.includes("accession")) {
      this.$store.commit("accessionStore/clearStore");
    }
    this.selectedCaseId = null;
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
    eventBus.$off(SIGNED_CASE_FROM_PDF);
  },
  watch: {
    selectedCaseId: {
      immediate: true,
      handler(nv, ov) {
        if (nv && nv !== ov) {
          this.handleLoadCase(nv);
        } else if (!nv) {
          this.selectedCaseDetails = {};
        }
      }
    },
    $route(to, from) {
      if (from.path.includes("quicksignout") && to.path.includes("accession")) {
        this.$store.commit("setReturnToQuickSignout", true);
      }
      if (from.path.includes("accession") && !to.path.includes("accession")) {
        this.$store.commit("setReturnToQuickSignout", false);
      }
    }
  },
  domStreams: ["selectionChange$", "contentReady$"],
  subscriptions() {
    return {
      firstContentReady$: this.contentReady$.pipe(
        take(1),
        tap(({ event }) => {
          const { element, component } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          searchPanel.focus();
          setTimeout(() => {
            component.selectRowsByIndexes([0]);
            const firstCaseId = component.getKeyByRowIndex(0);
            this.selectedCaseId = firstCaseId;
          }, 100);
        })
      ),
      selectRow$: this.contentReady$.pipe(
        tap(({ event }) => {
          const { component } = event.msg;
          setTimeout(() => {
            component.selectRowsByIndexes([0]);
          }, 100);
        })
      ),
      startUp$: this.contentReady$.pipe(
        filter(({ event }) => {
          const { element } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          if (searchPanel) {
            return true;
          }
          return false;
        }),
        map(({ event }) => {
          const { element, component } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          return [component, searchPanel];
        }),
        switchMap(([grid]) => {
          const selectNextRow = change => {
            const totalCount = grid.totalCount();
            const currentSelection = grid.getSelectedRowKeys();
            const currentSelectionIndex = grid.getRowIndexByKey(currentSelection[0]);
            if (
              currentSelectionIndex + change > -1 &&
              currentSelectionIndex + change < totalCount
            ) {
              const rowElements = grid.getRowElement(currentSelectionIndex + change);
              if (rowElements?.length) {
                rowElements.forEach(element => {
                  element.scrollIntoView(false);
                });
                const newRowKey = grid.getKeyByRowIndex(currentSelectionIndex + change);
                this.selectedCaseId = newRowKey;
                return grid.selectRows([newRowKey]);
              }
            }
            return;
          };
          function selectNextPage(change) {
            const totalCount = grid.pageCount();
            const currentPage = grid.pageIndex();
            if (currentPage + change > -1 && currentPage + change < totalCount) {
              return grid.pageIndex(currentPage + change);
            }
            return;
          }
          const keyDown$ = fromEvent(document.body, "keydown");
          const arrowUp$ = keyDown$.pipe(
            filter(e => e.keyCode === 38 && this.isActivated),
            tap(e => e.preventDefault()),
            map(() => -1),
            tap(selectNextRow)
          );
          const arrowDown$ = keyDown$.pipe(
            filter(e => e.keyCode === 40 && this.isActivated),
            tap(e => e.preventDefault()),
            map(() => 1),
            tap(selectNextRow)
          );
          const arrowRight$ = keyDown$.pipe(
            filter(e => e.keyCode === 39 && this.isActivated),
            map(() => 1),
            tap(selectNextPage)
          );
          const arrowLeft$ = keyDown$.pipe(
            filter(e => e.keyCode === 37 && this.isActivated),
            map(() => -1),
            tap(selectNextPage)
          );

          const selectionChanged$ = this.selectionChange$.pipe(
            tap(data => {
              const { selectedRowsData } = data.event.msg;
              this.selectedRows = selectedRowsData;
            })
          );
          return merge(arrowDown$, arrowUp$, arrowRight$, arrowLeft$, selectionChanged$);
        })
      )
    };
  },
  computed: {
    ...mapState({
      caseStatuses: state => state.caseStatuses,
      quickSignoutGridState: state => state.grids.quickSignout,
      AccessionNumberingType: state => state.labSettings.AccessionNumberingType,
      currentUser: state => state.currentUser,
      doctorProxies: state => state.doctorProxies,
      caseProcedures: state => state.accessionStore.caseOrders,
      caseHolds: state => state.accessionStore.caseHolds,
      caseTags: state => state.accessionStore.caseTags,
      caseNotes: state => state.accessionStore.caseNotes,
      caseImages: state => state.accessionStore.caseImages,
      confirmPrelimSignOuts: state => state.labSettings.ConfirmPrelimSignOuts,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      confirmRemoveHold: state => state.applicationSettings.confirmRemoveHold,
      reportCaseId: state => state.report.caseId
    }),
    ...mapGetters(["permissions"]),
    ...mapGetters("report", ["isInline"]),
    columnChooser() {
      return {
        enabled: true,
        allowSearch: true,
        mode: "select"
      };
    },
    accessionDataStore() {
      if (
        !this.hasBeenActivated ||
        (!this.currentUser.isPathologist && !this.doctorProxies?.length)
      ) {
        return new DataSource({
          store: []
        });
      }
      let statusFilter = [
        ["caseStatus", "=", CaseStatusEnum.ResultedOnHold],
        "or",
        ["caseStatus", "=", CaseStatusEnum.Resulted],
        "or",
        ["caseStatus", "=", CaseStatusEnum.ResultedAgain]
      ];
      if (this.showSigned) {
        statusFilter = [
          ...statusFilter,
          "or",
          ["caseStatus", "=", CaseStatusEnum.SignedOnHold],
          "or",
          ["caseStatus", "=", CaseStatusEnum.Signed],
          "or",
          ["caseStatus", "=", CaseStatusEnum.SignedAgain]
        ];
      }
      let pathologistFilter = [];
      if (this.currentUser.isPathologist) {
        pathologistFilter = [["pathologistGUID", "=", this.currentUser.id]];
      }
      if (this.showProxy && this.doctorProxies.length) {
        for (const proxyGUID of this.doctorProxies) {
          if (pathologistFilter.length) {
            pathologistFilter = [...pathologistFilter, "or", ["pathologistGUID", "=", proxyGUID]];
          } else {
            pathologistFilter = [["pathologistGUID", "=", proxyGUID]];
          }
        }
      }
      return new DataSource({
        store: CasesApi.searchStore,
        filter: [statusFilter, "and", pathologistFilter]
      });
    },
    columns() {
      const priorityStore = new CustomStore({
        key: ["id", "displayName"],
        load() {
          return DropdownApi.getPriorities();
        }
      });
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "120px"
        },
        {
          dataField: "caseCreationMethodId",
          caption: "Method",
          lookup: {
            dataSource: caseCreationMethodEnum,
            displayExpr: "displayName",
            valueExpr: "id"
          },
          dataType: "number"
        },
        {
          dataField: "priority",
          dataType: "string",
          allowSorting: true,
          calculateSortValue: "prioritySort",
          lookup: {
            dataSource: priorityStore,
            displayExpr: "displayName",
            valueExpr: "displayName"
          }
        },
        {
          dataField: "caseStatus",
          dataType: "string",
          allowFiltering: true,
          showInColumnChooser: false,
          defaultFilterValues: [],
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          caption: "Status",
          allowSearch: false
        },
        {
          dataField: "numberSequence",
          dataType: "number",
          caption: "Number"
        },
        {
          dataField: "accessionNumber",
          allowReordering: true,
          caption: "Case #",
          allowHeaderFiltering: false,
          dataType: "string",
          sortOrder: "desc",
          sortIndex: 0,
          allowSearch: true,
          calculateFilterExpression: caseGridAccessionNumberFilter(this.AccessionNumberingType),
          selectedFilterValue: "="
        },
        {
          dataField: "patientName",
          allowReordering: true,
          dataType: "string",
          caption: "Name",
          allowHeaderFiltering: false,
          allowSearch: true,
          calculateFilterExpression: value => calcultateNameFilter(value)
        },
        {
          dataField: "patientDOB",
          allowReordering: true,
          dataType: "date",
          caption: "DOB",
          allowHeaderFiltering: false
        },
        {
          dataField: "patientAgeAtReceiptDisplay",
          allowReordering: true,
          caption: "Age",
          dataType: "string",
          allowSearch: false,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowFiltering: false
        },
        {
          dataField: "patientSex",
          caption: "Gender",
          allowReordering: true,
          dataType: "number",
          width: "50px",
          lookup: {
            dataSource: [
              {
                text: "M",
                id: 0
              },
              {
                text: "F",
                id: 1
              },
              {
                text: "O",
                id: 2
              },
              {
                text: "U",
                id: 3
              }
            ],
            displayExpr: "text",
            valueExpr: "id"
          },
          allowHeaderFiltering: false
        },

        {
          dataField: "collectedOn",
          caption: "Collected",
          allowReordering: true,
          dataType: "date",
          allowHeaderFiltering: false
        },

        {
          dataField: "numberOfSpecimens",
          allowReordering: true,
          caption: "Quantity",
          dataType: "number",
          width: "50px",
          allowHeaderFiltering: false
        },
        {
          dataField: "patientMRN",
          allowReordering: true,
          caption: "MRN",
          dataType: "string",
          allowHeaderFiltering: false,
          allowFiltering: true,
          allowSearch: true
        },

        {
          dataField: "patientSSN",
          allowReordering: true,
          caption: "SSN",
          dataType: "string",
          visible: this.permissions.SocialSecurityNumberView,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          allowFiltering: false,
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "providerName",
          allowReordering: true,
          caption: "Provider",
          dataType: "string",
          allowSearch: true
        },
        {
          dataField: "providerOfficeName",
          caption: "Location",
          dataType: "string",
          allowReordering: true,
          allowSearch: true
        },
        {
          dataField: "receivedOn",
          allowReordering: true,
          caption: "Received",
          dataType: "date",
          allowHeaderFiltering: false
        },
        {
          dataField: "reportedOn",
          caption: "Reported",
          allowReordering: true,
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.reportedOn) {
              return this.calculateReportedOn(data.reportedOn);
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "reportedOn");
          },
          calculateSortValue: "reportedOn"
        },
        {
          dataField: "orderNumber",
          filterField: "OrderNumber",
          allowReordering: true,
          dataType: "string",
          caption: "Order #",
          allowSearch: true
        },
        {
          dataField: "accountNumber",
          filterField: "AcctNumber",
          dataType: "string",
          allowReordering: true,
          caption: "Account Number",
          allowSearch: true
        },
        {
          dataField: "pathologistName",
          caption: "Pathologist",
          dataType: "string",
          allowSearch: true
        },
        {
          dataField: "billingCycle",
          caption: "Billing Cycle",
          dataType: "string",
          lookup: {
            dataSource: this.billingCycles,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "hx",
          filterField: "hx",
          allowReordering: true,
          dataType: "boolean",
          cellTemplate: "booleanValue",
          allowHeaderFiltering: true,
          allowFiltering: false,
          caption: "HX",
          headerFilter: {
            dataSource: [
              {
                text: "True",
                value: ["hx", "=", true]
              },
              {
                text: "False",
                value: ["hx", "=", false]
              }
            ]
          }
        },
        {
          dataField: "ins",
          allowHeaderFiltering: true,
          filterField: "Ins",
          caption: "INS",
          allowReordering: true,
          dataType: "boolean",
          cellTemplate: "booleanValue",
          allowFiltering: false,
          headerFilter: {
            dataSource: [
              {
                text: "True",
                value: ["ins", "=", true]
              },
              {
                text: "False",
                value: ["ins", "=", false]
              }
            ]
          }
        }
      ];
    },
    stateStoring() {
      let customSave = this.customStateSave;
      let customLoad = this.customStateLoad;
      return {
        enabled: true,
        type: "custom",
        customSave,
        customLoad,
        ignoreColumnOptionNames: ["filterValue", "filterValues", "headerFilter", "filterType"]
      };
    },
    quickLinksButtons() {
      return [
        { displayName: "<u>O</u>rders", count: this.caseProcedures.length },
        { displayName: "Ho<u>l</u>ds", count: this.caseHolds.length },
        { displayName: "Ta<u>g</u>s", count: this.caseTags.length },
        { displayName: "<u>N</u>otes", count: this.caseNotes.length },
        { displayName: "<u>I</u>mages", count: this.caseImages.length }
      ];
    },
    osAltKey() {
      if (getOS().includes("Mac")) {
        return "control";
      }
      return "Alt";
    },
    toolbar() {
      return {
        visible: true,
        items: [
          {
            template: "nameAndCaseNumber",
            location: "center"
          },
          {
            template: "loader",
            visible: this.isLoading
          },
          { template: "signBtn" },
          {
            name: "columnChooserButton",
            visible: true
          },
          {
            name: "searchPanel",
            visible: true
          }
        ]
      };
    },
    shortkeys() {
      if (!this.isActivated) {
        return;
      }
      return getAltKeys([..."dighlnopqrstx".split(""), "f7", "f8", "f9", "f10", "f11", "f12"]);
    }
  },
  methods: {
    onContentReady() {
      addCommasToCount();
      const rows = this.grid.getVisibleRows();
      if (!rows.length) {
        this.selectedCaseId = null;
      }
      const rowData = rows.map(e => e.data);
      if (rowData) {
        if (rowData[0].caseId !== this.selectedCaseId && this.indexToSelect) {
          if (this.indexToSelect > rows.length - 1) {
            this.grid.selectRowsByIndexes([rows.length - 1]);
            const newCaseData = this.grid.getSelectedRowsData();
            this.selectedCaseId = newCaseData[0].caseId;
          } else {
            const newCaseData = rowData[this.indexToSelect];
            this.selectedCaseId = newCaseData.caseId;
            setTimeout(() => this.grid.selectRows([newCaseData.caseId]), 150);
            this.indexToSelect = null;
          }
        } else {
          this.selectedCaseId = rowData[0].caseId;
        }
      } else {
        this.grid.selectRowsByIndexes([0]);
        const newCaseData = this.grid.getSelectedRowsData();
        this.selectedCaseId = newCaseData[0].caseId;
      }
    },
    onInitialized(event) {
      this.grid = event.component;
    },
    handleCaseClick(event) {
      this.selectedCaseId = event.data.caseId;
    },
    calculateReportedOn(reportedOn) {
      if (reportedOn) {
        const formattedDate = formatDatetimeCell(reportedOn);
        return format(formattedDate, "M/dd/yyyy");
      }
      return "";
    },
    customStateSave(gridState) {
      const payload = {
        name: "quickSignout",
        gridState,
        columns: this.columns
      };

      this.$store.dispatch("grids/setGridState", payload);
    },
    async customStateLoad() {
      return this.quickSignoutGridState;
    },
    toggleShowSigned() {
      this.showSigned = !this.showSigned;
    },
    async handleLoadCase(caseId) {
      this.isLoading = true;
      this.grid.selectRows(caseId);
      const caseDetails = this.grid.getSelectedRowsData();
      this.selectedCaseDetails = caseDetails[0];
      if (this.reportCaseId !== caseId) {
        await this.$store.dispatch("report/viewPathReport", { caseId: caseId });
      }
      await this.$store.dispatch("accessionStore/getCaseQuickLinks", caseId);
      this.isLoading = false;
    },
    handleNavigateToCase(caseId, tab) {
      let routeName = "CaseView";
      switch (tab) {
        case "specimen":
          routeName = "Specimen";
          break;
        case "results":
          routeName = "SpecimenResults";
          break;
        case "transactions":
          routeName = "accesionTransactions";
          break;
      }
      const routeData = {
        name: routeName,
        params: { caseId }
      };
      this.$router.push(routeData);
    },
    handleQuicklinksButtonClick({ displayName }) {
      const property = `is${displayName.replaceAll(/<\/?u>/g, "")}Open`;
      this[property] = true;
      if (property === "isHoldsOpen") {
        this.initialHoldsCount = this.caseHolds.length;
      }
    },
    handleShortkey({ srcKey }) {
      if (!this.isActivated || this.isLoading) {
        return;
      }
      switch (srcKey) {
        // Navigate to case on respective tab
        case "d":
          this.handleNavigateToCase(this.selectedCaseId, "demographics");
          break;
        case "s":
          this.handleNavigateToCase(this.selectedCaseId, "specimens");
          break;
        case "r":
          this.handleNavigateToCase(this.selectedCaseId, "results");
          break;
        case "t":
          this.handleNavigateToCase(this.selectedCaseId, "transactions");
          break;
        // Open quicklinks
        case "n":
        case "f7":
          this.isNotesOpen = !this.isNotesOpen;
          break;
        case "o":
        case "f8":
          this.isOrdersOpen = !this.isOrdersOpen;
          break;
        case "i":
        case "f9":
          this.isImagesOpen = !this.isImagesOpen;
          break;
        case "g":
        case "f10":
          this.isTagsOpen = !this.isTagsOpen;
          break;
        case "l":
        case "f11":
          this.isHoldsOpen = !this.isHoldsOpen;
          break;
        case "x":
        case "altX":
        case "f12":
          this.handleSignSelectedCase();
          break;
        // Other
        case "h":
          this.toggleHistory();
          break;
        case "p":
          this.scrollToPathReport();
          break;
        case "q":
          this.removeAllHolds();
          break;
        default:
          console.log(srcKey);
          break;
      }
    },
    async handleSignSelectedCase() {
      if (this.isLoading || !this.isActivated) {
        return;
      }
      const caseDetails = this.selectedCaseDetails;
      this.payloadToSign = null;
      if (this.confirmPrelimSignOuts && caseDetails.caseStatus === CaseStatusEnum.ResultedOnHold) {
        const confirm = await window.confirm(
          "This case has a hold code attached. Are you sure you want to sign it as prelim?"
        );
        if (!confirm) {
          return;
        }
      }
      try {
        this.isLoading = true;
        const { caseStatus, collectedOn } = this.selectedCaseDetails;
        if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
          this.isLocationPopupOpen = true;
          this.payloadToSign = caseDetails;
          return;
        }
        const isSigned = [7, 8, 10, 11, 13, 14].includes(caseStatus);
        if (!isSigned && !collectedOn) {
          this.isCollectedPopupOpen = true;
          return;
        } else {
          const caseId = caseDetails.caseId;
          document.body.style.cursor = "wait";
          await this.$store.dispatch("accessionStore/handleSignCase", {
            caseId,
            labLocationId: this.currentLabLocation,
            skipRefresh: true,
            caseStatus
          });
          if (this.showSigned) {
            this.$store.dispatch("report/viewPathReport", { caseId: this.selectedCaseId });
          }
          window.notify(
            `Successfully ${isSigned ? "unsigned" : "signed"} accession ${
              caseDetails.accessionNumber
            }. `
          );
          // if (SignResponse?.batchRedirect) {
          //   batchRedirect();
          // }
          document.body.style.cursor = "";
          this.handleRefreshGrid();
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        document.body.style.cursor = "";
      }
    },
    handleKeydown({ key }) {
      if (!this.isActivated) {
        return;
      }
      const el = document.activeElement;
      if (
        !this.isActivated ||
        el.tagName === "INPUT" ||
        this.isOrdersOpen ||
        this.isHoldsOpen ||
        this.isTagsOpen ||
        this.isNotesOpen ||
        this.isImagesOpen
      ) {
        return;
      }
      if (key === "x") {
        this.handleSignSelectedCase();
      }
    },
    handleSelectLabLocation() {
      this.payloadToSign = null;
      this.isLocationPopupOpen = false;
      this.handleSignSelectedCase();
    },
    async handleSetCollected(collectedOnDate) {
      this.isCollectedPopupOpen = false;
      const caseDetails = await CasesApi.getCaseById(this.selectedCaseId);
      const updatedCase = await this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...caseDetails,
        collectedOn: collectedOnDate
      });
      this.selectedCaseDetails = {
        ...updatedCase,
        caseStatus: updatedCase?.caseStatus || updatedCase?.status
      };
      this.handleSignSelectedCase();
    },
    scrollToPathReport() {
      const pathReportEl = document.getElementsByClassName("viewer");
      const pageBody = document.getElementsByClassName("quick-signout-page");
      if (pathReportEl && this.isInline) {
        const currentScroll = pageBody[0].scrollTop;
        if (currentScroll === this.pathReportPosition) {
          scrollToElement(pageBody[0]);
        } else {
          scrollToElement(pathReportEl[0]);
          setTimeout(() => {
            const newPosition = document.getElementsByClassName("quick-signout-page");
            this.pathReportPosition = newPosition[0].scrollTop;
          }, 500);
        }
      }
    },
    handleScroll() {
      this.isScrolledToPathReport = false;
    },
    onRowPrepared(row) {
      if (row.rowType === "data") {
        if (
          [CaseStatusEnum.SignedOnHold, CaseStatusEnum.Signed, CaseStatusEnum.SignedAgain].includes(
            row.data.caseStatus
          )
        ) {
          row.rowElement.classList.add("signed-row");
        }
      }
    },
    async removeAllHolds() {
      if (!this.caseHolds?.length) {
        return;
      }
      const confirm = this.confirmRemoveHold
        ? await window.confirm(`Are you sure you want to remove all holds on this case?`)
        : true;
      if (!confirm) {
        return;
      }
      this.isLoading = true;
      try {
        const caseId = this.selectedCaseId;
        await this.$store.dispatch("accessionStore/removeQuickLink", {
          type: "H",
          caseId,
          ids: this.caseHolds.map(e => e.id)
        });

        window.notify("Removed all holds from case.");
        await this.$store.dispatch("accessionStore/getCaseQuickLinks", caseId);
        this.grid.refresh();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleHistory() {
      if (this.selectedCaseDetails?.hx) {
        this.isHistoryOpen = !this.isHistoryOpen;
      }
    },
    closeReport() {
      this.$store.commit("report/toggleReportViewer", false);
    },
    handleRefreshGrid(signedFromPdf) {
      if (signedFromPdf && !this.isActivated) {
        return;
      }
      if (this.grid.refresh) {
        const selectedRows = this.grid.getSelectedRowKeys();
        const index = this.grid.getRowIndexByKey(selectedRows[0]);
        this.indexToSelect = index;
        this.grid.refresh(true);
      }
    },
    handleCloseHolds() {
      this.isHoldsOpen = false;
      if (this.initialHoldsCount > 0 !== this.caseHolds.length > 0) {
        this.handleRefreshGrid();
      }
      this.initialHoldsCount = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

::v-deep .dx-pager {
  justify-content: flex-start;
  color: $primary-dark;
  font-weight: 600;
  font-size: 1rem;
  .dx-pages {
    margin: auto;
  }
}
.slider.round:before {
  border-radius: 50%;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
.quicklink-button {
  width: 7rem;
}
.caseNumber {
  margin-left: 0.5em;
  width: 18rem;
}
.history-button {
  position: relative;
}
.transparent {
  opacity: 0;
}
</style>
